import React from "react"
import { navigate } from "gatsby"

import right from "../../icons/right.svg"
import vipRight from "../../icons/vipRight.svg"
import superVipRight from "../../icons/superVipRight.svg"
import vip from "../../icons/vip.svg"
import superVip from "../../icons/superVip.svg"
import wrong from "../../icons/wrong.svg"

import Layout from "../../layouts"
import MembershipSidebar from "../../components/MembershipSidebar";

import { getNewestVersion } from "../../api/version"
import { getUserVipBenefit } from "../../api/user"
import "./vip.css"
import { convertByteUnit } from "../../utils/helper"
import { MEMBERSHIP_SIDEBAR } from "../../utils/constant"

class Vip extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      windows: "",
      mac: "",
      extension: "",

      userBenefit: null,
      vipBenefit: null,
      superVipBenefit: null,
    }
  }

  componentDidMount() {
    this.init()
    this.fetchUserVipBenefit();
  }

  init = async () => {
    const res = await getNewestVersion()
    if (res.data.success) {
      const data = res.data.data
      this.setState({ windows: data.feature.windows, mac: data.feature.mac, extension: data.feature.extension })
    }
  }

  fetchUserVipBenefit = async () => {
    const res = await getUserVipBenefit()
    if (res.data.success) {
      const { userBenefit, vipBenefit, superVipBenefit } = res.data.data
      this.setState({ userBenefit, vipBenefit, superVipBenefit })
    }
  }

  getEditorIntro = () => {
    const rightIcon = (
      <img alt="" src={right} className="mdnice-membership-table-icon" />
    )
    const wrongIcon = (
      <img alt="" src={wrong} className="mdnice-membership-table-icon-wrong" />
    )
    const functionList = [
      "markdown 编辑和排版",
      "云端文件同步",
      "云端文件管理",
      "主题订阅",
      "图片长期存储",
      "图片云端压缩",
      "本地文件系统管理",
      "本地图片自动上传",
      "多平台分发",
      "支持设备",
      "试用期",
      "新功能体验",
      "其它特性",
      "相关教程",
    ]
    const functionDom = functionList.map((item, index) =>
      index % 2 == 0 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )

    const localEditorList = [
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      "支持发布",
      "激活码激活的电脑设备",
      "7 天",
      "优先支持",
      "本地编辑，无网络可正常使用",
      <a href="https://www.mdnice.com/writing/76bc9003291b4797a4bfc6407a343bf3" target="_blank">教程</a>,
    ]
    const localEditorDom = localEditorList.map((item, index) =>
      index % 2 == 0 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )

    const webEditorList = [
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      wrongIcon,
      wrongIcon,
      wrongIcon,
      "支持发布",
      "Chrome 浏览器",
      "无",
      "支持",
      "网页编辑，更换电脑无障碍",
      <a href="https://www.mdnice.com/writing/f8af366b739846b68dc70ad33238720e" target="_blank">教程</a>,
    ]
    const webEditorDom = webEditorList.map((item, index) =>
      index % 2 == 0 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )

    const extentionList = [
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      rightIcon,
      wrongIcon,
      wrongIcon,
      wrongIcon,
      "一键授权、支持发布",
      "Chrome 浏览器",
      "无",
      "支持",
      "嵌入公众号后台，无缝衔接",
      <a href="https://www.mdnice.com/writing/88c7cf8bc94248cebae4888ee93216b2" target="_blank">教程</a>,
    ]
    const extentionDom = extentionList.map((item, index) =>
      index % 2 == 0 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )
    return (
      <div className="mdnice-membership-table">
        <div
          className="mdnice-membership-table-coloum"
          style={{ borderRight: "none" }}
        >
          <div className="mdnice-membership-table-row-header">
            <p>功能特性</p>
          </div>
          <div
            className="mdnice-membership-table-row-dark"
            style={{ fontWeight: "bold" }}
          >
            产品下载
          </div>
          {functionDom}
        </div>
        <div className="mdnice-membership-table-coloum-recommand">
          <div className="mdnice-membership-table-row-header">
            <p>本地编辑器</p>
            <div className="mdnice-membership-table-activecode-btn-container">
              <a
                className="mdnice-membership-table-activecode-btn"
                style={{ width: "110px" }}
                onClick={() => {
                  navigate("/membership/open-vip")
                }}
              >
                开通会员激活
              </a>
            </div>
          </div>
          <div className="mdnice-membership-table-row-dark">
            <div className="mdnice-membership-table-activecode-btn-container">
              <a
                className="mdnice-membership-table-activecode-btn"
                onClick={() => {
                  window.open(this.state.mac)
                  navigate("/article/user/desktop/")
                }}
              >
                mac 版
              </a>
              <a
                className="mdnice-membership-table-activecode-btn"
                onClick={() => {
                  window.open(this.state.windows)
                }}
              >
                windows 版
              </a>
            </div>
          </div>

          {localEditorDom}
        </div>
        <div
          className="mdnice-membership-table-coloum"
          style={{ borderLeft: "none" }}
        >
          <div className="mdnice-membership-table-row-header">
            <p>网页编辑器</p>
          </div>
          <div className="mdnice-membership-table-row-dark">
            <a
              className="mdnice-membership-table-btn"
              onClick={() => {
                window.open("https://www.mdnice.com")
              }}
            >
              在线访问
            </a>
          </div>
          {webEditorDom}
        </div>
        <div
          className="mdnice-membership-table-coloum"
          style={{ borderLeft: "none" }}
        >
          <div className="mdnice-membership-table-row-header">
            <p>浏览器插件</p>
          </div>
          <div className="mdnice-membership-table-row-dark">
            <a
              className="mdnice-membership-table-btn"
              onClick={() => {
                window.open(this.state.extension)
              }}
            >
              点击下载
            </a>
          </div>
          {extentionDom}
        </div>
      </div>
    )
  }

  getVipIntro = () => {
    const superVipRightIcon = (
      <img alt="" src={superVipRight} className="mdnice-membership-table-icon" />
    )
    const vipRightIcon = (
      <img alt="" src={vipRight} className="mdnice-membership-table-icon" />
    )
    const rightIcon = (
      <img alt="" src={right} className="mdnice-membership-table-icon" />
    )
    const wrongIcon = (
      <img alt="" src={wrong} className="mdnice-membership-table-icon-wrong" />
    )
    const functionList = [
      "云端文件夹数量",
      "云端文章数量",
      "图片存储量",
      <strong style={{ color: "#666" }}>本地编辑器激活码</strong>,
      "批量移动文件",
      "优先客户支持",
      "专项会员标记",
    ]
    const functionDom = functionList.map((item, index) =>
      index % 2 == 1 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )

    const superVipList = [
      "无限制",
      "无限制",
      "无限制",
      "1个，支持" + this.state.superVipBenefit?.activeCodeDeviceNum + "台设备，可续期",
      superVipRightIcon,
      superVipRightIcon,
      superVipRightIcon,
    ]
    const superVipDom = superVipList.map((item, index) =>
      index % 2 == 1 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )

    const vipList = [
      this.state.vipBenefit?.catalogNum + "个",
      this.state.vipBenefit?.articleNum + "篇",
      convertByteUnit(this.state.vipBenefit?.imageStorageByte),
      "1个，支持" + this.state.vipBenefit?.activeCodeDeviceNum + "台设备，可续期",
      vipRightIcon,
      vipRightIcon,
      vipRightIcon,
    ]
    const vipDom = vipList.map((item, index) =>
      index % 2 == 1 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )

    const userList = [
      this.state.userBenefit?.catalogNum + "个",
      this.state.userBenefit?.articleNum + "篇",
      convertByteUnit(this.state.userBenefit?.imageStorageByte),
      wrongIcon,
      wrongIcon,
      wrongIcon,
      wrongIcon,
    ]
    const userDom = userList.map((item, index) =>
      index % 2 == 1 ? (
        <div className="mdnice-membership-table-row">{item}</div>
      ) : (
        <div className="mdnice-membership-table-row-dark">{item}</div>
      )
    )
    return (
      <div className="mdnice-membership-table">
        <div
          className="mdnice-membership-table-coloum"
          style={{ borderRight: "none" }}
        >
          <div className="mdnice-membership-table-row-header">
            <p>会员权益</p>
          </div>
          {functionDom}
        </div>
        <div className="mdnice-super-vip-table-coloum-recommend">
          <div className="mdnice-membership-table-row-header">
            <div className="super-vip-header">
              <img alt="" src={superVip} className="super-vip-header-icon" />
              <p>超级会员</p>
            </div>

            <div className="super-vip-btn-container">
              <a
                className="super-vip-open-btn"
                onClick={() => {
                  navigate("/membership/open-vip")
                }}
              >
                立即开通
              </a>
            </div>
          </div>

          {superVipDom}
        </div>
        <div className="mdnice-vip-table-coloum-recommend">
          <div className="mdnice-membership-table-row-header">
            <div className="vip-header">
              <img alt="" src={vip} className="vip-header-icon" />
              <p>高级会员</p>
            </div>
            <div className="mdnice-membership-table-vip-btn-container">
              <a
                className="mdnice-vip-table-btn"
                onClick={() => {
                  navigate("/membership/open-vip")
                }}
              >
                立即开通
              </a>
            </div>
          </div>

          {vipDom}
        </div>
        <div
          className="mdnice-membership-table-coloum"
          style={{ borderLeft: "none" }}
        >
          <div className="mdnice-membership-table-row-header">
            <p>注册用户</p>
          </div>
          {userDom}
        </div>
      </div>
    )
  }

  render() {
    return (
      <Layout isSpacerDisplay={true} title="会员权益">
        <div className="mdnice-membership-container">
          <div className="mdnice-membership-table-container">
            <MembershipSidebar sidebarType={MEMBERSHIP_SIDEBAR.VIP} />
            <div className="mdnice-product-intro">
              <h2 className="mdnice-product-title">会员权益</h2>
              <div className="mdnice-vip-intro">
                {this.getVipIntro()}
              </div>
              <h2 className="mdnice-product-title">编辑器功能特性</h2>
              <div className="mdnice-editor-intro">
                {this.getEditorIntro()}
              </div>
            </div>

          </div>
        </div>
      </Layout>
    )
  }
}

export default Vip
